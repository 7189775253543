import { useState, useEffect } from "react";
import axios from "axios";
import Header from "./components/Header";
// import { FaReact } from "react-icons/fa";
import Loading from "./components/Loading";
// import DayButton from "./components/Buttons/DayButton";
// import MarketButton from "./components/Buttons/MarketButton";
import Card from "./components/Card";

const App = () => {
  const [matches, setMatches] = useState([]);
  const [market, setMarket] = useState("1x2");
  const [day, setDay] = useState("");
  const [loading, setLoading] = useState(true);
  const dayButtons = document.querySelectorAll(".dayButton");
  const marketButtons = document.querySelectorAll(".marketButton");

  const marketHandler = (market, e) => {
    marketButtons.forEach((button) => {
      button.classList.remove("active");
    });
    e.target.classList.add("active");
    setMarket(market);
  };

  const dayHandler = (day, e) => {
    dayButtons.forEach((button) => {
      button.classList.remove("active");
    });
    e.target.classList.add("active");
    setDay(day);
  };

  useEffect(() => {
    const path = `${market}/${day}`;
    const options = {
      method: "GET",
      url: `https://betmaniagr.herokuapp.com/${path}`,
    };
    if (sessionStorage.getItem(path)) {
      setLoading(false);
      setMatches(JSON.parse(sessionStorage.getItem(path)));
    } else {
      setLoading(true);
      axios
        .request(options)
        .then((res) => {
          const json = res.data;
          json.data.sort((a, b) => {
            if (a.start_date > b.start_date) return 1;
            if (a.start_date < b.start_date) return -1;
            return 0;
          });
          setMatches(json.data);
          setLoading(false);
          sessionStorage.setItem(path, JSON.stringify(json.data));
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [market, day]);

  // axios
  //   .request(options)
  //   .then((res) => {
  //     const json = res.data;
  //     json.data.sort((a, b) => {
  //       if (a.start_date > b.start_date) return 1;
  //       if (a.start_date < b.start_date) return -1;
  //       return 0;
  //     });
  //     setMatches(json.data);
  //     setLoading(false);
  //   })
  //   .catch((err) => {
  //     console.log(err.message);
  //   });

  const LoadingCards = () => {
    let loadingCards = [];
    for (let i = 0; i < 20; i++) {
      // loadingCards.push(<Loading key={i} />);
      loadingCards.push(<Loading key={i} />);
    }
    return loadingCards;
  };

  return (
    <>
      <Header
        onClickDayYesterday={(e) => dayHandler("yesterday", e)}
        onClickDayToday={(e) => dayHandler("", e)}
        onClickDayTomorrow={(e) => dayHandler("tomorrow", e)}
        // onClickDayTwoDays={(e) => dayHandler("2days", e)}
        onClickMarketClassic={(e) => marketHandler("1x2", e)}
        onClickMarketGG={(e) => marketHandler("gg", e)}
        onClickMarketO25={(e) => marketHandler("o25", e)}
        onClickMarketO35={(e) => marketHandler("o35", e)}
      />
      <main>
        {!loading &&
         matches.filter((match)=>match.odds[match.prediction]>1.7).map((match, _index) => <Card key={_index} match={match} />)}
        {loading && LoadingCards()}
      </main>
    </>
  );
};

export default App;
