import './card.css';
const Card = ({_index,match,onClick}) => {
  const clickCard = (e) => {
    // e.target.classList.toggle('active')
  }


  return (
    <div onClick={clickCard} key={_index} className={match.status==="won"? "card won" : match.status==="lost"? "card lost" : "card"}>
      <p className="date">{match.start_date}</p>
              <p className="vs">
                {match.home_team} vs {match.away_team}
              </p>
              <div className="prediction">
                <span>{match.prediction}</span>@
                <span className="odds">{match.odds[match.prediction]}</span>
              </div>
    </div>
  )
}

export default Card
