import './header.css';
import { FaFutbol } from "react-icons/fa";
import DayButton from "./Buttons/DayButton";
import MarketButton from "./Buttons/MarketButton";

const Header = ({onClickDayYesterday,onClickDayToday,onClickDayTomorrow,onClickDayTwoDays, onClickMarketClassic,onClickMarketGG,onClickMarketO25,onClickMarketO35, favClick}) => {
  return (
    <header>
      <div>
          <DayButton
            onClick={onClickDayYesterday}
            label="Yesterday"
          />
          <DayButton
            onClick={onClickDayToday}
            label="Today"
            active={true}
          />
          <DayButton
            onClick={onClickDayTomorrow}
            label="Tomorrow"
          />
          {/*<DayButton*/}
          {/*  onClick={onClickDayTwoDays}*/}
          {/*  label="2 Days"*/}
          {/*/>*/}
        </div>
        <p onClick={favClick} className="icon">
          <FaFutbol />
        </p>
        <div>
          <MarketButton
            onClick={onClickMarketClassic}
            label="1x2"
            active={true}
          />
          <MarketButton
            onClick={onClickMarketGG}
            label="G/G"
          />
          <MarketButton
            onClick={onClickMarketO25}
            label="O 2,5"
          />
          <MarketButton
            onClick={onClickMarketO35}
            label="O 3,5"
          />
        </div>
    </header>
  )
}

export default Header
