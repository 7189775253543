import "./buttonsHeader.css";
const DayButton = ({label, onClick, active=false}) => {
  return (
    <>
      <button className={active===true? 'dayButton active':'dayButton'} onClick={onClick}>{label}</button>
    </>
  )
}

export default DayButton
