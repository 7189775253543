import './card.css';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const Loading = () => {
  return (
    <div className="card skeleton">
      <SkeletonTheme color="#312F2F" highlightColor="#3E3D3D">
        <Skeleton className="date skeleton" width={130}  />
        <Skeleton className="vs skeleton" width={250} />
        <Skeleton className="prediction skeleton" width={110} />
      </SkeletonTheme>
    </div>
  )
}

export default Loading;