import './buttonsHeader.css';
const MarketButton = ({label, onClick, active=false}) => {
  return (
    <>
      <button className={active===true? 'marketButton active':'marketButton'} onClick={onClick}>{label}</button>
    </>
  )
}

export default MarketButton
